import React, { Component, useEffect, useState, setState } from 'react';
import { Navbar, Nav, FormControl, Container, Form, Button } from 'react-bootstrap';
import { BrowserRouter as Router, Routes, Route, useHref, NavLink } from 'react-router-dom';
import axios from 'axios';

import './header.css'

import logoleft from './Лого в 1 строку.svg';
import logoright from './head_logo_fasie.png';

import Techno from '../../Pages/Techno';
import Service from '../../Pages/Service';
import About from '../../Pages/About';
import TechnoView from '../../Pages/TechnoView';
import TechnoSearch from '../../Pages/TechnoSearch';

//import filejson from '../card/mock1.json';


//export default class Header extends Component {
export default function Header () {
//    render() {

const [search, setSearch] = useState('');
//console.log(search);


const [linkSearch, setLinkSearch] = useState('http://89.108.99.73/search/');

const onSearch = (searchTerm) => {
    //console.log(searchTerm);

    // новая ссылка на JSON
    setLinkSearch(`http://89.108.99.73/search/${searchTerm}`);
    
    // обновление компонента с реестром
    <TechnoSearch parametr={linkSearch}/>;

    // переход на страницу
    //window.location.assign(`/search/${searchTerm}`);
    window.location.href = `/search/${searchTerm}`;

};


const [postsH, setPostsH] = useState([]);

useEffect(() => {
    const fetchDataH = async () => {
        const resultH = await axios(
            'http://89.108.99.73/api/getJSON',
        );
        setPostsH(resultH.data);
//        console.log(result.data);
    };
    fetchDataH();
}, []);

let itemH = postsH[0];
//console.log(postsH);
//console.log(itemH);
//console.log(Object.keys(itemH).length);



        return (
            <>
                <Navbar fixed='top' collapseOnSelect expand='xl' variant='light' style={{ display: 'table-caption', background: '#fff'}} >

                    <Container className='search_margin' >
                        <Navbar.Brand href='/' className='navbar__logo'>
                            <img
                                src={logoleft}
                                height='64'
                                width='270'
                                className='d-inline-block align-top'
                                alt='logoleft'
                            />
                            
                        </Navbar.Brand>

                        <Navbar.Toggle aria-controls='responsive-navbar-nav' />

                        <Navbar.Collapse id='responsive-navbar-nav' className='navbar__nav_text'>
                            <Nav className='mr-auto'>
                                <Nav.Link href='/' > Технологии </Nav.Link>
                                <Nav.Link href='/service' activeStyle={{ color: 'red' }}> Услуги </Nav.Link>
                                <Nav.Link href='/about'> О компании </Nav.Link>
                                
                            </Nav>
                        </Navbar.Collapse>

                        <img
                            src={logoright}
                            className='d-inline-block align-top header_image'
                            alt='logoright'
                        />
                    </Container>

                    <Container >
                        <Form style={{ display: 'flex', width: '100%' }} className='formSearch'>
                            <Form.Control
                                type='text'
                            //    value={valueSearch}
                                onChange={ ( e ) => { setSearch(e.target.value) } }
                                placeholder='Найти технологию'
                                className='styleSearch'
                            > 
                            </Form.Control>

                            <Button 
                                onClick={ () => onSearch(search) }
                                variant='outline-info'
                            > 
                                <svg className='iconBtn' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" 
                                    d="m20.87 20.17-5.59-5.59C16.35 13.35 17 11.75 17 10c0-3.87-3.13-7-7-7s-7 3.13-7 7 3.13 7 7 7c1.75 0 3.35-.65 4.58-1.71l5.59 5.59.7-.71zM10 16c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6z" />
                                </svg>
                            </Button>
                        </Form>
                    </Container>

                </Navbar>

                <Router>
                    <Routes>
                        <Route exact path='/' element={<Techno />} />
                        <Route exact path='/service' element={<Service />} />
                        <Route exact path='/about' element={<About />} />

                        <Route path='/search/:itemId' element={<TechnoSearch parametr={linkSearch} />} />
                        

                        {(itemH) ? (<Route exact path='/technoview/1' element={<TechnoView parametr={itemH.Card1} />} />) : (console.log())}
                        {(itemH) ? (<Route exact path='/technoview/2' element={<TechnoView parametr={itemH.Card2} />} />) : (console.log())}
                        {(itemH) ? (<Route exact path='/technoview/3' element={<TechnoView parametr={itemH.Card3} />} />) : (console.log())}
                        {(itemH) ? (<Route exact path='/technoview/4' element={<TechnoView parametr={itemH.Card4} />} />) : (console.log())}
                        {(itemH) ? (<Route exact path='/technoview/5' element={<TechnoView parametr={itemH.Card5} />} />) : (console.log())}
                        {(itemH) ? (<Route exact path='/technoview/6' element={<TechnoView parametr={itemH.Card6} />} />) : (console.log())}
                        {(itemH) ? (<Route exact path='/technoview/7' element={<TechnoView parametr={itemH.Card7} />} />) : (console.log())}
                        {(itemH) ? (<Route exact path='/technoview/8' element={<TechnoView parametr={itemH.Card8} />} />) : (console.log())}
                        {(itemH) ? (<Route exact path='/technoview/9' element={<TechnoView parametr={itemH.Card9} />} />) : (console.log())}
                        {(itemH) ? (<Route exact path='/technoview/10' element={<TechnoView parametr={itemH.Card10} />} />) : (console.log())}
                        {(itemH) ? (<Route exact path='/technoview/11' element={<TechnoView parametr={itemH.Card11} />} />) : (console.log())}
                        {(itemH) ? (<Route exact path='/technoview/12' element={<TechnoView parametr={itemH.Card12} />} />) : (console.log())}
                        {(itemH) ? (<Route exact path='/technoview/13' element={<TechnoView parametr={itemH.Card13} />} />) : (console.log())}
                        {(itemH) ? (<Route exact path='/technoview/14' element={<TechnoView parametr={itemH.Card14} />} />) : (console.log())}
                        {(itemH) ? (<Route exact path='/technoview/15' element={<TechnoView parametr={itemH.Card15} />} />) : (console.log())}
                        {(itemH) ? (<Route exact path='/technoview/16' element={<TechnoView parametr={itemH.Card16} />} />) : (console.log())}
                        {(itemH) ? (<Route exact path='/technoview/17' element={<TechnoView parametr={itemH.Card17} />} />) : (console.log())}
                        {(itemH) ? (<Route exact path='/technoview/18' element={<TechnoView parametr={itemH.Card18} />} />) : (console.log())}
                        {(itemH) ? (<Route exact path='/technoview/19' element={<TechnoView parametr={itemH.Card19} />} />) : (console.log())}
                        {(itemH) ? (<Route exact path='/technoview/20' element={<TechnoView parametr={itemH.Card20} />} />) : (console.log())}

                    </Routes>
                </Router>

            </>
        );
//    }
}






/*
<div className='navbar__logo_text'>Маркетплейс российских научных технологий</div>

<Nav.Link href='/technoview'> Карточка технологии </Nav.Link>

<Route exact path='/technoview' element={<TechnoView />} />

{HandleClick(filejson.Card1.id)}

onChange={ (e) => console.log(e.target.value) }


                        <Route exact path="/search" component={SearchResults} />
                        <Route path="/search/:id" component={Video} />
*/

    // var result = `this is just a ${sample}`
    // строю страницу на основе JSON с результатом поиска http://89.108.99.73/search/Lizonka
    // 
    // кнопка поиска должна быть ссылкой перехода на новую страницу по аналогии с реестром Techno
    // <TechnoSearch parametr={linkSearch} />
    // передавать в таком виде 'http://89.108.99.73/api/getJSON'


/*
// попытка поиска
const filteredCountries = countries.filter(country => {
    return country.name.toLowerCase().includes(value.toLowerCase())
    })
*/

//    var formH = null;
//    const setFieldH = (value) => { formH = value };

/*
// вроде как можно удалить:
    const HandleClick = ( valueId ) => {
        const newHref={}
//        newHref = useHref( `/technoview/${filejson.Card1.id}` )
        newHref = useHref( `/technoview/${valueId}` )
        return newHref
    }
*/
