import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

import './about.css'

import photoabout from './photoabout.jpg';

export default class About extends Component {
    render() {
        return (
            <div className='paddingAbout'>
                <Container className='contentAbout'>
                    <div className='textAbout'>
                        Наш проект реализуется при поддержке фонда содействия инновациям и направлен на связь компаний с научными коллективами. Мы не продаём технологии, а позволяем найти подходящую и выйти на её владельцев. У нас Вы можете найти информацию о научных технологиях и подобрать себе подходящую.
                        Мы же с радостью Вас проконсультируем и поможем подобрать ту самую, которая даст наилучший рост Вашей организации. Мы открыты в обратной связи - будем рады.
                    </div>

                    {
                        /*
                    <img
                    src={photoabout}
                    height='230'
                    width='350'
                    className='d-inline-block align-right techno_image_About'
                    alt='photoabout'
                    
                />
                        */
                    }

                </Container>
            </div>
        );
    }
}